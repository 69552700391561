import React from 'react'

const About = () => {
  return (
    <div className="about-page">
      ETrader is an in-house trading interface to various exchange. So traders
      can freely trade, without actually having access to the exchange's account.
    </div>
  );
};

export default About
