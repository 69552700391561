import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './assets/scss/main.scss';
import App from './components/App';
import AppNav from './components/AppNav';
import About from './components/About';

const Root = () => {
  return (
    <Router>
      <App>
        <Routes>
            <Route exact={true} path="/" element={<About />}/>
        </Routes>
      </App>
    </Router>
  );
};

export default Root;
