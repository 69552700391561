window._currentUser = JSON.parse(localStorage.getItem('currentUser'))

export const authService = {
    login,
    logout,
    isAuthenticated,
    token,
};

function isAuthenticated() {
  const user = window._currentUser

  return !!user && user.expires != "" && new Date(user.expires) > new Date()
}

function token() {
  const user = window._currentUser

  return !!user && user.token
}

function login(username, password) {
  /*
  const api = new API();

  return api.login(username, password)
    .then(auth => {
      const user = {
        token: auth.token,
        expires: auth.expires,
      }

      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('currentUser', JSON.stringify(user));
      window._currentUser = user
      return user;
    })
    */
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('currentUser');
  window._currentUser = null
}

