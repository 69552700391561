import React from 'react'
import classNames from 'classnames'
import { nanosecondsToStr } from '../utils/numberFormat'
import {
  capitalize,
  spreadPcnt,
  bindAppendSymbol,
  coerceOptValue,
} from '../utils/strFormat'

class RefMarkets extends React.Component {
  constructor(props) {
    super(props)
  }

  renderRefMarket(refMarket) {
    let fxContent

    if(refMarket.fx_side) {
      fxContent = (
        <span>
          <strong>Fx:</strong> { refMarket.fx_market_id } &lt;-&gt; ({ refMarket.fx_rate } | {  refMarket.fx_side })
        </span>
      )
    }

    return (
      <div className="data__options" key={ refMarket.market_id }>
        <div className="data__option">
          <div className="data__option-title">{ refMarket.market_id }</div>
          <strong>Pxs:</strong> { refMarket.buy_price } { this.props.market.pair.quote } <strong>[buy]</strong> { refMarket.sell_price } { this.props.market.pair.quote } <strong>[sell]</strong>&nbsp;
          { fxContent }
        </div>
      </div>
    )
  }

  render() {
    let refMarkets = this.props.refMarkets || []

    refMarkets.sort((a, b) => (a.buy_price > b.buy_price) ? 1 : -1)

    let content = refMarkets.map((ref) => { return this.renderRefMarket(ref) })

    return (
      <div className="data data--fill-height">
        <div className="data__title">
          Ref Markets
        </div>
        { content }
      </div>
    )
  }
}

export default RefMarkets
