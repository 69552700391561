export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function spreadPcnt(spread) {
  return (spread*100) + "%"
}

export function bindAppendSymbol(symbol) {
  return function appendSymbol(value) {
    return `${value} ${symbol}`
  }
}

export function coerceOptValue(value) {
  switch (typeof value) {
  case 'boolean':
  case 'number':
    return value.toString()
  case 'function':
    return value()
  case 'object':
    if(Array.isArray(value)) {
      return value.map(coerceOptValue).join(', ')
    } else if(value instanceof Date) {
      return value.toLocaleTimeString()
    } else if(value == null) {
      return ""
    }
    break
  default:
    return value
  }
}

