import React from 'react'
import { Link } from "react-router-dom";

class AppTerminal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: "",
    }
  }

  handleChange(e) {
    this.setState({ value: e.target.value })
  }

  setValue(value) {
    this.setState({
      value,
    })

    this.termInput.focus()
  }

  submit(e) {
    e.preventDefault()

    if(this.state.value.trim() != "") {
      this.setState({ value: "" })
      this.props.sendCommand(this.state.value)
    }

    return false
  }

  render() {
    return (
      <div className="app-terminal">
        <div className="app-terminal__log">
          <div className="app-terminal__line">
          {
            this.props.termLines.map((termLine, i) => {
              return (<div key={ i }>{ termLine }</div>)
            })
          }
          </div>
        </div>
        <div className="text-input-hint" title="Commands to send over to the server">
          <strong>Usage:</strong>&nbsp;
          {this.renderHints()}
        </div>
        <div className="app-terminal__input text-input">
          <form className="text-input__input-container" onSubmit={this.submit.bind(this)}>
            <input
              autoFocus
              ref={(el) => { this.termInput = el; }}
              type="text"
              className="text-input__input"
              placeholder="Type command..."
              value={this.state.value}
              onChange={this.handleChange.bind(this)}
            />

            <button className="text-input__enter">
              ENTER
            </button>
          </form>
        </div>
      </div>
    )
  }

  renderHints() {
    const hints = [
      "login ",
      "trollbox ",
    ]

    const list = hints.map((value, index) => {
      return (
        <a onClick={this.setValue.bind(this, value)} key={index}>
          {value}
          {index < hints.length - 1 && ", "}
        </a>
      )
    })

    return list
  }
}

export default AppTerminal
