import React from 'react'
import classNames from 'classnames'
import { nanosecondsToStr } from '../utils/numberFormat'

class ExecStats extends React.Component {
  constructor(props) {
    super(props)
  }

  renderStat(stat) {
    let str = ""
    let volume = stat.buy_total + stat.sell_total
    let moneyVolume = (stat.buy_total * stat.buy_avg_px) + (stat.sell_total * stat.sell_avg_px)
    let netPosition = stat.buy_total - stat.sell_total
    let minTotal = Math.min(stat.buy_total, stat.sell_total)
    let pnl = (minTotal * stat.sell_avg_px) - (minTotal * stat.buy_avg_px)
    let spread = 0.0
    let base = this.props.market.pair.base
    let quote = this.props.market.pair.quote

    if(stat.sell_avg_px != 0 && stat.buy_avg_px != 0) {
      spread = (stat.sell_avg_px / stat.buy_avg_px) - 1.0
    }

    return (
      <div className="data__options" key={ stat.time_frame }>
        <div className="data__option">
          <div className="data__option-title">{ nanosecondsToStr(stat.time_frame) }</div>
          <div>
            <strong>Volume:</strong> { volume } { quote } |&nbsp;
            <strong>Px:</strong> { stat.buy_avg_px } <strong>[bid]</strong> { stat.sell_avg_px } <strong>[ask]</strong> |&nbsp;
            <strong>Spread:</strong> { (spread*100).toFixed(3) }% |&nbsp;
            <strong>Position:</strong> <span className={ classNames(
              { "data--red": netPosition < 0 },
              { "data--green": netPosition > 0 },
            ) }>{ netPosition } { base }</span>&nbsp;
            <strong>PnL:</strong> <span className={ classNames(
              { "data--red": pnl < 0 },
              { "data--green": pnl > 0 },
            ) }>{ pnl } { quote }</span>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let content = []

    for(let k in this.props.exec_stats) {
      let stat = this.props.exec_stats[k]
      content.push(this.renderStat(stat))
    }

    return (
      <div className="data">
        <div className="data__title">
          Executions stats
        </div>
        { content }
      </div>
    )
  }
}

export default ExecStats
