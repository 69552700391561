import React from 'react'
import classNames from 'classnames'

const Row = (trade) => {
  const side = trade.side || trade.direction
  const time = new Date(trade.timestamp);

  let icon

  if(side == 'bid') {
    icon = <i className="zmdi zmdi-chevron-up"></i>
  } else {
    icon = <i className="zmdi zmdi-chevron-down"></i>
  }

  return (
    <div
      className={classNames(
        "trades__row",
        { "trades__row--bid": side == 'bid' },
        { "trades__row--ask": side == 'ask' },
      )}
      key={trade.id}
    >
      <div key="side" className="trades__row__side">{ icon }</div>
      <div key="time" className="trades__row__time">{ time.toLocaleTimeString() }</div>
      <div key="price" className="trades__row__price">{ trade.price }</div>
      <div key="amount" className="trades__row__amount">{ trade.amount }</div>
    </div>
  )
}

class Trades extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const trades = this.props.trades

    let rows = []

    for(let index in trades) {
      const trade = trades[index]

      rows.push(Row(trade))
    }

    return (
      <div className="trades">
        <div className="trades__title">
          Last Market Trades
        </div>
        <div className="trades__header">
          <div className="trades__header__side"></div>
          <div className="trades__header__time">Time</div>
          <div className="trades__header__price">Price</div>
          <div className="trades__header__amount">Amount</div>
        </div>
        <div className="trades__rows">
          { rows }
        </div>
      </div>
    )

  }
}

export default Trades
