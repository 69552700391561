import React from 'react'
import { Link } from "react-router-dom";

class Trollbox extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: "",
    }
  }

  handleChange(e) {
    const { value } = e.target

    this.setState({
      value,
    })
  }

  submit(e) {
    e.preventDefault()

    if(this.state.value.trim() != "") {
      this.setState({ value: "" })
      this.props.sendTrollbox(this.state.value)
    }

    return false
  }

  render() {
    return (
      <div className="trollbox">
        <div className="trollbox__messages">
          {
            this.props.messages.map((msg, i) => {
              return (
                <div key={i} className="trollbox__line">
                  <span>{ msg.userID }: </span>
                  <span>{ msg.message }</span>
                </div>
              )
            })
          }
        </div>
        <div className="trollbox__input text-input">
          <form className="text-input__input-container" onSubmit={this.submit.bind(this)}>
            <input
              type="text"
              className="text-input__input"
              placeholder="Message..."
              value={this.state.value}
              onChange={this.handleChange.bind(this)}
            />

            <button className="text-input__enter">
              ENTER
            </button>
          </form>
        </div>
      </div>
    )
  }
}

export default Trollbox
