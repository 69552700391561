import React from 'react'
import OrderBook from './OrderBook'
import Trades from './Trades'
import Inventory from './Inventory'
import BotOptions from './BotOptions'
import RefMarkets from './RefMarkets'
import ExecStats from './ExecStats'
import OpenPositions from './OpenPositions'
import BookBuilder from '../lib/BookBuilder'
import classNames from 'classnames'

class Bots extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      curSelectedBot: "",
    }

    this.lastUpdateAt = new Date()
  }

  shouldComponentUpdate() {
    const now = new Date()
    const ms = now.getTime() - this.lastUpdateAt.getTime()

    return ms > 200 // 200ms
  }

  componentDidUpdate() {
    this.lastUpdateAt = new Date()
  }

  renderBotsList() {
    return Object.keys(this.props.bots).map((key) => {
      let bot = this.props.bots[key]

      let bestBid = 0.0
      let bestAsk = 0.0
      let topRefSpreadBid = 0.0
      let topRefSpreadAsk = 0.0
      if(bot.bids && bot.bids.length > 0) {
        bestBid = bot.bids[0].price
        topRefSpreadBid = bot.bids[0].ref_spread
      }
      if(bot.asks && bot.asks.length > 0) {
        bestAsk = bot.asks[0].price
        topRefSpreadAsk = bot.asks[0].ref_spread
      }

      const pos_pcnt = (total, max, currency) => {
        let pcntStr = null
        let pcnt = 0
        if(max !== 0) {
          pcnt = ((total / max) * 100).toFixed(2)
          pcntStr = `[${pcnt}%]`
        }

        return (
          <span className={classNames(
            { "data--yellow": pcnt >= 80 && pcnt < 90 },
            { "data--red": pcnt >= 90 },
          )}>
            { total } { currency } { pcntStr }
          </span>
        )
      }

      // Remove positions that are not open, that have no order_id
      const openPositions = bot.positions.filter((position) => {
        return position.order_id != ""
      })

      return (
        <div key={key} className="bots__list-item" onClick={ () => { this.setState({ curSelectedBot: key }) } }>
          <div>{ bot.market.exchange.name } / { bot.market.symbol }</div>
          <div>{ bot.bot_id } { bot.account_id }</div>
          <div>Open orders: { openPositions.length } / { bot.positions.length }</div>
          <div>Stock balance: { bot.stock.available } { bot.market.pair.base }</div>
          <div>Top pxs: { bestBid } { bot.market.pair.quote } [bid] | { bestAsk } { bot.market.pair.quote } [ask]</div>
          <div>Ref spreads: { (topRefSpreadBid*100).toFixed(2) }% [bid] | { (topRefSpreadAsk*100).toFixed(2) }% [ask]</div>
          <div>Position: { pos_pcnt(bot.stock.total, bot.options.inventory.max_stock_inventory, bot.market.pair.base) } | { pos_pcnt(bot.money.total, bot.options.inventory.max_money_inventory, bot.market.pair.quote) }</div>
        </div>
      )
    })
  }

  renderCurrentBot() {
    let content = null

    if(this.state.curSelectedBot in this.props.bots) {
      let bot = this.props.bots[this.state.curSelectedBot]

      content = this.renderBotView(bot)
    } else {
      content = (
        <div className="bots__text-content">
          Select a bot to view its state
        </div>
      )
    }

    return content
  }

  renderBotView(bot) {
    let book = new BookBuilder({
      depth: 20,
    })

    for(let i in bot.bids) {
      book.update({
        side: 'bid',
        ...bot.bids[i],
      })
    }
    for(let i in bot.asks) {
      book.update({
        side: 'ask',
        ...bot.asks[i],
      })
    }

    return (
      <div className="bots__content">
        <div className="bots__content-group">
          <div className="bots__book">
            <OrderBook
              exchange={bot.market.exchange.name}
              symbol={bot.market.symbol}
              pair={bot.market.pair}
              book={book}
            />
          </div>
          <div className="bots__trades">
            <Trades
              trades={bot.last_market_trades}
            />
          </div>
          <div className="bots__ref-markets">
            <RefMarkets
              refMarkets={bot.ref_markets}
              market={bot.market}
            />
          </div>
        </div>
        <OpenPositions
          positions={bot.positions || []}
        />
        <Inventory
          stock={bot.stock}
          money={bot.money}
          options={bot.options.inventory}
          pair={bot.market.pair}
        />
        <BotOptions
          options={bot.options}
          market={bot.market}
        />
        <ExecStats
          exec_stats={bot.exec_stats}
          market={bot.market}
        />
    </div>
    )
  }

  render() {
    return (
      <div className="bots">
        <div className="bots__list">
          { this.renderBotsList() }
        </div>
        { this.renderCurrentBot() }
      </div>
   )
  }
}

export default Bots
