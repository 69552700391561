import React from 'react'
import AppMenu from './AppMenu'
import AppTerminal from './AppTerminal'
import Trollbox from './Trollbox'
import Logs from './Logs'
import Bots from './Bots'
import logo from '../assets/images/logo.svg';

import BotCommander from '../services/botCommander';

class App extends React.Component {
  constructor(props) {
    super(props)

    this.botCommander = new BotCommander()
    this.botCommander.registerWsMsgCb(this.wsMsgRcv.bind(this))
    this.componentMounted = false
    this.lastUpdateAt = new Date()

    this.state = {
      termLines: [],
      trollboxMessages: [],
      logLines: [],
      logsAutoScroll: true,
      bots: {},
    }
  }

  componentDidMount() {
    if(!this.componentMounted) {
      this.connectToBotCommander()
    }

    this.componentMounted = true
  }

  connectToBotCommander() {
      this.addLog("Connecting to bot commander websocket...")
      this.botCommander.onOpen(() => {
        this.addLog("Connected to websocket!")
      })
      this.botCommander.onClose(() => {
        this.addLog("Disconnected from websocket, reconnecting in 3 seconds...")
        setTimeout(() => {
          this.connectToBotCommander()
        }, 3000)
      })
      this.botCommander.connect()
  }

  updateBotState(botSt) {
    let id = botSt["id"]
    this.state.bots[id] = botSt

    this.setState({ bots: this.state.bots })
  }

  wsMsgRcv(data) {
    let msg = JSON.parse(data)

    if(msg.type === 'trollbox') {
      this.state.trollboxMessages.push(msg.data)
      this.setState({ trollboxMessages: this.state.trollboxMessages })
    } else if(msg.type === 'command') {
      this.state.termLines.push(msg.data.message)
      this.setState({ termLines: this.state.termLines })
    } else if(msg.type === 'bot_state') {
      this.updateBotState(msg.data)
    } else {
      this.addLog("Received unprocessable message from ws: " + msg.type)
    }
  }

  sendCommand(cmd) {
      this.botCommander.send(cmd)
  }

  sendTrollbox(cmd) {
      this.botCommander.send("tb " + cmd)
  }

  logsTriggerAutoScroll() {
    this.setState({ logsAutoScroll: true })
  }

  logsTriggerManualScroll() {
    this.setState({ logsAutoScroll: false })
  }

  addLog(message, time) {
    let currentRows = this.state.logLines || []

    if(typeof time == 'undefined') {
      time = new Date()
    }

    currentRows.push({
      time,
      message,
    })

    const maxRows = 100000

    if(currentRows.length > maxRows) {
      currentRows = currentRows.slice(-maxRows)
    }

    this.setState({
      logLines: currentRows,
    })
  }


  render() {
    return (
      <div className="app-container">
        <div className="app-container__header">
          <img
            className="app-nav__logo"
            src={logo}
            alt="Atomic"
            style={{
              width: 'auto',
              height: '25px',
              marginTop: '5px',
              marginLeft: '25px',
            }}
          />
        </div>
      { /* TODO 
        <div className="app-container__nav">
          <AppMenu />
        </div>
      */ }
        <div className="app-container__center">
          <div className="app-container__content">
            <Bots
              bots={this.state.bots}
            />
          </div>
        </div>
        <div className="app-container__center">
          <div className="app-container__terminal">
            <div className="app-container__title">Terminal</div>
            <AppTerminal sendCommand={ this.sendCommand.bind(this) } termLines={ this.state.termLines } />
          </div>
          <div className="app-container__trollbox">
            <div className="app-container__title">Trollbox</div>
            <Trollbox sendTrollbox={ this.sendTrollbox.bind(this) } messages={ this.state.trollboxMessages }  />
          </div>
          <div className="app-container__logs">
            <div className="app-container__title">Logs</div>
            <Logs
              rows={this.state.logLines}
              autoScroll={this.state.logsAutoScroll}
              triggerAutoScroll={this.logsTriggerAutoScroll.bind(this)}
              triggerManualScroll={this.logsTriggerManualScroll.bind(this)}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default App
