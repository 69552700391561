export default function numberPrecision(number) {
  const str = String(number)
  const parts = str.split(".")

  if(parts.length > 1) {
    return parts[1].length
  } else {
    return 0
  }
}
