import React from 'react'
import classNames from 'classnames'

class Inventory extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="data">
        <div className="data__title">
          Balance
        </div>
        <div className="data__balances">
          <div className="data__balance">
            <div className="data__balance-title">
              Stock
            </div>
            <div className="data__balance-content">
              <div>{ this.props.stock.available } { this.props.pair.base } <span>Available</span></div>
              <div>{ this.props.stock.total } { this.props.pair.base } <span>Total</span></div>
              <div>{ this.props.options.min_stock_inventory } { this.props.pair.base } <span>Min</span></div>
              <div>{ this.props.options.max_stock_inventory } { this.props.pair.base } <span>Max</span></div>
            </div>
          </div>
          <div className="data__balance">
            <div className="data__balance-title">
              Money
            </div>
            <div className="data__balance-content">
              <div>{ this.props.money.available } { this.props.pair.quote } <span>Available</span></div>
              <div>{ this.props.money.total } { this.props.pair.quote } <span>Total</span></div>
              <div>{ this.props.options.min_money_inventory } { this.props.pair.quote } <span>Min</span></div>
              <div>{ this.props.options.max_money_inventory } { this.props.pair.quote } <span>Max</span></div>
            </div>
          </div>
        </div>
      </div>
    )

  }
}

export default Inventory
