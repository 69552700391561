import numberPrecision from '../utils/numberPrecision'
import {
  sortNumberAsc,
  sortNumberDesc,
} from '../utils/numberSort'

export default class BookBuilder {
  constructor({ depth = 20 }) {
    this.maxDepth = depth

    this.basePrecision = 0
    this.quotePrecision = 0

    this.minAmount = 0
    this.maxAmount = 0

    this.bids = {
      data: {},
      prices: [],
      minAmount: 0,
      maxAmount: 0,
    }
    this.asks = {
      data: {},
      prices: [],
      minAmount: 0,
      maxAmount: 0,
    }
  }

  // Update order book w/ given order
  // * We will use the order's price as the "book level"
  // * If the amount is zero, the level (price point) will be
  // removed from the book
  // * If the amount is higher than zero, the level (price point)
  // will be created or updated
  update(order) {
    const side = order.side
    const book = (side == 'bid' ? this.bids : this.asks)
    const prices = book.prices

    const index = prices.findIndex(price => price == order.price)

    // Remove from book if amount is zero
    if(order.amount <= 0 && index !== -1) {
      delete book.data[order.price]
      book.prices.splice(index, 1)
    }
    else if (order.amount > 0) {
      // Include to book if new level
      if (index === -1) {
        book.prices.push(order.price)
      }

      book.data[order.price] = {
        price: order.price,
        amount: order.amount,
        has_open_orders: order.has_open_orders,
        top_spread: order.top_spread,
        ref_spread: order.ref_spread,
      }
    }

    // Reorder price levels
    if(side == 'bid') {
      book.prices = book.prices.sort(sortNumberDesc)
    }
    else {
      book.prices = book.prices.sort(sortNumberAsc)
    }

    // Min/max of amount, so we can rank each price level
    book.minAmount = Math.min(...book.prices.slice(0, this.maxDepth).map(p => book.data[p].amount))
    book.maxAmount = Math.max(...book.prices.slice(0, this.maxDepth).map(p => book.data[p].amount))

    this.minAmount = Math.min(this.bids.minAmount, this.asks.minAmount)
    this.maxAmount = Math.max(this.bids.maxAmount, this.asks.maxAmount)

    // Deduce book floating point precision
    // TODO: We should maintain a list of each asset's precision, this is a hack
    const basePrecision = numberPrecision(order.amount)
    const quotePrecision = numberPrecision(order.price)

    if(basePrecision > this.basePrecision) this.basePrecision = basePrecision
    if(quotePrecision > this.quotePrecision) this.quotePrecision = quotePrecision
  }

  state() {
    return {
      basePrecision: this.basePrecision,
      quotePrecision: this.quotePrecision,
      minAmount: this.minAmount,
      maxAmount: this.maxAmount,
      bids: {
        ...this.bids,
        prices: this.bids.prices.slice(0, this.maxDepth),
      },
      asks: {
        ...this.asks,
        prices: this.asks.prices.slice(0, this.maxDepth),
      },
    }
  }
}
