export default class BotCommander {
  constructor(options = {}) {
    const production = process.env.NODE_ENV === 'production'

    if(production) {
      this.addr = "wss://bm-server.atomic.fund/ws"
    }
    else {
      this.addr = "ws://localhost:8000/ws"
      this.addr = "wss://bm-server.atomic.fund/ws"
    }

    this.ws = null
    this.wsMsgCbs = []
    this.onOpenCb = null
    this.onCloseCb = null
  }

  connect() {
    this.ws = new window.WebSocket(this.addr)

    this.ws.addEventListener('open', this._onOpen.bind(this))
    this.ws.addEventListener('close', this._onClose.bind(this))
    this.ws.addEventListener('message', this._onMessage.bind(this))
  }

  registerWsMsgCb(fnc) {
    this.wsMsgCbs.push(fnc)
  }

  _onOpen() {
    console.log(`WS conn to ${this.addr} open`)

    if(typeof this.onOpen == 'function') {
      this.onOpenCb()
    }
  }

  _onClose() {
    console.log(`WS conn to ${this.addr} closed`)

    if(typeof this.onClose == 'function') {
      this.onCloseCb()
    }
  }

  onOpen(cb) {
    this.onOpenCb = cb
  }

  onClose(cb) {
    this.onCloseCb = cb
  }

  _onMessage(event) {
    for(let i in this.wsMsgCbs) {
      this.wsMsgCbs[i](event.data)
    }
  }

  send(msg) {
    this.ws.send(msg)
  }
}
