import React from 'react'

const PriceLevel = (side, price, total, book, book_state) => {
  const data = book.data[price]
  const amount = data.amount

  // Calculate order amount relative to current book depth
  const range = book_state.maxAmount - book_state.minAmount
  const correctedStartValue = amount - book_state.minAmount
  const amountPercent = (correctedStartValue * 100) / range

  let content = []
  let background

  let rgbaPercent = '0.25'
  if(data.has_open_orders) {
    rgbaPercent = '0.50'
  }

  if(side == 'bid') {
    if(data.has_open_orders) {
      content.push(<div key="open_order" className="order-book__open-bid"></div>)
    }
    content.push(
      <div key="ref_spread">{ (data.ref_spread * 100).toFixed(2) }%</div>,
      <div key="top_spread">{ (data.top_spread * 100).toFixed(2) }%</div>,
      <div key="price">{ price }</div>,
      <div key="amount">{ amount }</div>,
      <div key="total">{ total }</div>,
    )
    background = `linear-gradient(270deg, rgba(0, 255, 0, ${rgbaPercent}) ${amountPercent}%, rgba(0, 0, 0, 0.0) ${amountPercent}%)`
  }
  else {
    content.push(
      <div key="ref_spread">{ (data.ref_spread * 100).toFixed(2) }%</div>,
      <div key="top_spread">{ (data.top_spread * 100).toFixed(2) }%</div>,
      <div key="price">{ price }</div>,
      <div key="amount">{ amount }</div>,
      <div key="total">{ total }</div>,
    )
    if(data.has_open_orders) {
      content.push(<div key="open_order" className="order-book__open-ask"></div>)
    }
    background = `linear-gradient(270deg, rgba(255, 0, 0, ${rgbaPercent}) ${amountPercent}%, rgba(0, 0, 0, 0.0) ${amountPercent}%)`
  }

  return (
    <div key={price} className="order-book__level" style={{
      background: background,
    }}>
      { content }
    </div>
  )
}

class OrderBook extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const book_state = this.props.book.state()

    let bids = []
    let bidVolume = 0
    let bidTotalPriceVolume = 0
    let asks = []
    let askTotalPriceVolume = 0
    let askVolume = 0

    for(let index = 0; index < book_state.bids.prices.length; index++) {
      const price = book_state.bids.prices[index]
      const data = book_state.bids.data[price]

      bidTotalPriceVolume += data.price * data.amount
      bidVolume += data.amount

      bids.push(PriceLevel('bid', price, bidVolume, book_state.bids, book_state))
    }

    for(let index = book_state.asks.prices.length-1; index >= 0; index--) {
      const price = book_state.asks.prices[index]
      const data = book_state.asks.data[price]

      askTotalPriceVolume += data.price * data.amount
      askVolume += data.amount

      asks.push(PriceLevel('ask', price, askVolume, book_state.asks, book_state))
    }

    // Calculate bid/ask vwap
    const bidVWAP = parseFloat(bidTotalPriceVolume / bidVolume).toFixed(book_state.quotePrecision)
    const askVWAP = parseFloat(askTotalPriceVolume / askVolume).toFixed(book_state.quotePrecision)

    // Round for display purposes
    bidVolume = parseFloat(bidVolume).toFixed(book_state.basePrecision)
    askVolume = parseFloat(askVolume).toFixed(book_state.basePrecision)

    return (
      <div className="order-book">
        <div className="order-book__title">
          {this.props.symbol}
          <span className="order-book__title__subtitle">
            {this.props.exchange}
          </span>
        </div>
        <div className="order-book__bid-asks">
          <div className="order-book__header">
            <div>Ref spread</div>
            <div>Top spread</div>
            <div>Price</div>
            <div>Amount</div>
            <div>Total</div>
          </div>
          <div className="order-book__asks">
            {asks}
          </div>
          <div className="order-book__bids">
            {bids}
          </div>
        </div>
        <div className="order-book__stats">
          <div className="order-book__stats-row order-book__stats-row--half">
            <div className="order-book__stats-row__label">
              Bid VWAP
            </div>
            <div className="order-book__stats-row__value">
              { bidVWAP } { this.props.pair.quote }
            </div>
          </div>
          <div className="order-book__stats-row order-book__stats-row--half">
            <div className="order-book__stats-row__label">
              Ask VWAP
            </div>
            <div className="order-book__stats-row__value">
              { askVWAP } { this.props.pair.quote }
            </div>
          </div>
          <div className="order-book__stats-row order-book__stats-row--half">
            <div className="order-book__stats-row__label">
              Bid volume:
            </div>
            <div className="order-book__stats-row__value">
              { bidVolume } { this.props.pair.base }
            </div>
          </div>
          <div className="order-book__stats-row order-book__stats-row--half">
            <div className="order-book__stats-row__label">
              Ask volume:
            </div>
            <div className="order-book__stats-row__value">
              { askVolume } { this.props.pair.base }
            </div>
          </div>
        </div>
      </div>
    )

  }
}

export default OrderBook
