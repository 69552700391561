import React from 'react'
import classNames from 'classnames'
import { nanosecondsToStr } from '../utils/numberFormat'
import {
  capitalize,
  spreadPcnt,
  bindAppendSymbol,
  coerceOptValue,
} from '../utils/strFormat'

class BotOptions extends React.Component {
  constructor(props) {
    super(props)
  }

  renderSideOptions(side) {
    let options = this.props.options[side]

    let opts = [
      { key: "min_spread", title: "Min Spread", formatFunc: spreadPcnt },
      { key: "max_spread", title: "MaxSpread", formatFunc: spreadPcnt },
      { key: "aggressiveness", title: "Aggressiveness" },
      { key: "min_spread_between", title: "MinSpreadBetween", formatFunc: spreadPcnt },
      { key: "max_orders", title: "MaxOrders" },
      { key: "order_size", title: "OrderSize", formatFunc: bindAppendSymbol(this.props.market.pair.base) },
      { key: "max_order_size", title: "MaxOrderSize", formatFunc: bindAppendSymbol(this.props.market.pair.base) },
      { key: "min_order_size", title: "MinOrderSize", formatFunc: bindAppendSymbol(this.props.market.pair.base) },
      { key: "min_order_value", title: "MinOrderValue", formatFunc: bindAppendSymbol(this.props.market.pair.quote) },
      { key: "front_run_by", title: "FrontRunBy", formatFunc: bindAppendSymbol(this.props.market.pair.quote) },
      { key: "front_run_if_size", title: "FrontRunIfSize", formatFunc: bindAppendSymbol(this.props.market.pair.base) },
      { key: "allow_trade_empty_book", title: "AllowTradeEmptyBook" },
      { key: "order_sizing", title: "OrderSizing" },
      { key: "order_sizing_multiplier", title: "OrderSizingMultiplier" },
      { key: "spread_sizing", title: "SpreadSizing" },
      { key: "spread_sizing_multiplier", title: "SpreadSizingMultiplier" },
      { key: "taker_min_spread", title: "TakerMinSpread", formatFunc: spreadPcnt },
      { key: "taker_max_spread", title: "TakerMaxSpread", formatFunc: spreadPcnt },
    ]

    let content = []

    for(let k in opts) {
      let opt = opts[k]

      if(options[opt.key]) {
        content.push(this.renderOpt(opt.title, options[opt.key], opt.formatFunc))
      }
    }

    return (
      <div>
        <div className="data__title">
          { capitalize(side) + " Options" }
        </div>
        <div className="data__options">
          { content }
        </div>
      </div>
    )
  }

  renderOpt(title, value, formatFunc) {
    if(typeof formatFunc === 'function') {
      value = formatFunc(value)
    }

    return (
      <div className="data__option" key={ title }>
        <div className="data__option-title">{ title }</div>
        <div>{ coerceOptValue(value) }</div>
      </div>
    )
  }

  render() {
    let opts = [
      { key: "account_id", title: "AccountID", },
      { key: "gateway_id", title: "GatewayID" },
      { key: "symbol", title: "Symbol" },
      { key: "allow_n_cancel_errs", title: "AllowNCancelErrs" },
      { key: "allow_cancel_errs", title: "AllowCancelErrs" },
      { key: "allow_trade_empty_book", title: "AllowTradeEmptyBook" },
      { key: "spread_from", title: "SpreadFrom" },
      { key: "ref_price", title: "RefPrice" },
      { key: "ref_price_fixed", title: "RefPriceFixed", formatFunc: bindAppendSymbol(this.props.market.pair.base) },
      { key: "ref_exchanges", title: "RefExchanges" },
      { key: "asset_mappings", title: "AssetMappings" },
      { key: "fx_all_markets", title: "FxAllMarkets" },
      { key: "warmup_period", title: "WarmupPeriod", formatFunc: nanosecondsToStr },
      { key: "taker_arb_interval", title: "TakerArbInterval", formatFunc: nanosecondsToStr },
    ]

    let content = []

    for(let k in opts) {
      let opt = opts[k]

      if(this.props.options[opt.key]) {
        content.push(this.renderOpt(opt.title, this.props.options[opt.key], opt.formatFunc))
      }
    }

    return (
      <div className="data">
        <div className="data__title">
          Options
        </div>
        <div className="data__options">
          { content }
        </div>

        { this.renderSideOptions('default') }
        { this.renderSideOptions('bid') }
        { this.renderSideOptions('ask') }

        <div className="data__title">
          Inventory Options
        </div>
        <div className="data__options">
          <div className="data__option">
            <div className="data__option-title">Min Stock Inventory:</div>
            <div>{ this.props.options.inventory.min_stock_inventory } { this.props.market.pair.base }</div>
          </div>
          <div className="data__option">
            <div className="data__option-title">Max Stock Inventory:</div>
            <div>{ this.props.options.inventory.max_stock_inventory } { this.props.market.pair.base }</div>
          </div>

          <div className="data__option">
            <div className="data__option-title">Min Money Inventory:</div>
            <div>{ this.props.options.inventory.min_money_inventory } { this.props.market.pair.quote }</div>
          </div>
          <div className="data__option">
            <div className="data__option-title">Max Money Inventory:</div>
            <div>{ this.props.options.inventory.max_money_inventory } { this.props.market.pair.quote }</div>
          </div>
        </div>
      </div>
    )
  }
}

export default BotOptions
