import React from 'react'
import classNames from 'classnames'

const Row = (position) => {
  const side = position.side

  return (
    <div
      className={classNames(
        "table__row",
        { "table__row--bid": side == 'bid' },
        { "table__row--ask": side == 'ask' },
      )}
      key={position.side + position.id}
    >
      <div key="side" className="trades__row__side">{ position.side }</div>
      <div key="order_id" className="trades__row__time">{ position.order_id || "-" }</div>
      <div key="state" className="trades__row__time">{ position.state }</div>
      <div key="price" className="trades__row__price">{ position.price }</div>
      <div key="left_amount" className="trades__row__amount">{ position.left_amount }</div>
    </div>
  )
}

class OpenPositions extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const positions = this.props.positions

    // Remove positions that are not open, that have no order_id
    let openPositions = positions.filter((position) => {
      return position.order_id != "unused"
    })

    // Sort positions first by asks, then bids, then by index
    openPositions.sort((a, b) => {
      if (a.side == b.side) {
        return a.id > b.id ? 1 : -1
      } else {
        return a.side == 'ask' ? -1 : 1
      }
    })

    let rows = []
    for(let index in openPositions) {
      rows.push(Row(openPositions[index]))
    }

    return (
      <div className="table">
        <div className="table__title">
          Open Positions
        </div>
        <div className="table__header">
          <div className="table__header__side">Side</div>
          <div className="table__header__time">Order ID</div>
          <div className="table__header__time">State</div>
          <div className="table__header__price">Price</div>
          <div className="table__header__amount">Left Amount</div>
        </div>
        <div className="table__rows">
          { rows }
        </div>
      </div>
    )
  }
}

export default OpenPositions
