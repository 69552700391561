function pad(n, z) {
  z = z || 2;
  return ('00' + n).slice(-z);
}

export const nanosecondsToStr = (nano) => {
  let t = nano / 1000 / 1000

  let ms = t % 1000;
  t = (t - ms) / 1000;
  let secs = t % 60;
  t = (t - secs) / 60;
  let mins = t % 60;
  let hrs = (t - mins) / 60;

  let str = ""
  if(hrs > 0) {
    str += `${pad(hrs)}h`
  }
  if(mins > 0) {
    str += `${pad(mins)}m`
  }
  if(secs > 0) {
    str += `${pad(secs)}s`
  }
  if(ms > 0) {
    str += `${pad(ms, 3)}ms`
  }

  return str
}
